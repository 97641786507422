const education = [
  {
    date: "December 2024 - Present",
    title: "CS50 - CS50's Introduction to Computer Science, Computer Science.",
  },
  {
    date: "January 2019 - March 2023",
    title:
      "Universidad Alonso de Ojeda - Bachelor's degree, Computer Engineering.",
    evaluation:
      "https://www.linkedin.com/in/carlosdp7/overlay/1735577558492/single-media-viewer/?profileId=ACoAADdYLecBEG3gQpCDTJpRsN-RpCWu8aq9mvo",
  },
];

export default education;
