import React from "react";
import { Row, Col } from "react-bootstrap";
import "./style.scss";

export default function Skills() {
  return (
    <Row className="resume-info">
      <Col xs={12} lg={7}>
        <h2>Skills</h2>
      </Col>
      <Col xs={12} lg={5}>
        <Row className="info">
          <Col xs={12}>
            <p>
              <b>Programming Languages:</b> JavaScript, TypeScript.
            </p>
            <p>
              <b>Frameworks and Tools:</b> Node.js, NestJS, TypeScript, TypeORM,
              Postgres, Auth0, AWS, Terraform, Terraspace, Jest, Docker,
              Kubernetes, Mockoon, Amazon SQS, Amazon SNS, Amazon Pintpoint,
              Mermaid, Serverless, AWS Lambda, Redis, Microservices, React.js,
              Next.js, Git, Playwright, MongoDB, Express.js, Socket.io,
              Redis-based queue Bull, NATS Streaming Server, Nginx, Digital
              Ocean.
            </p>
            <p>
              <b>Soft:</b> Leadership, Emotional Intelligence, Resilience,
              Adaptability, Empathy, Teamwork, Time Management, Problem Solving.
            </p>
            <p>
              <b>Agile:</b> Scrum, Kanban.
            </p>
            <p>
              <b>Languages:</b> <i>Advanced:</i> English, <i>Native:</i>{" "}
              Spanish.
            </p>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
