import React from "react";
import { Row, Col } from "react-bootstrap";
import education from "../utils/education";
import "./style.scss";

export default function Education() {
  return (
    <Row className="resume-info">
      <Col xs={12} lg={5}>
        <h2>Education</h2>
      </Col>
      <Col xs={12} lg={7}>
        {education.map((education, index) => (
          <Row key={index} className="info">
            <Col xs={4} className="d-none d-lg-flex justify-content-lg-end">
              <span>{education.date}</span>
            </Col>
            <Col xs={8}>
              <div>
                <h3 className="mb-0 mb-lg-3">{education.title}</h3>
                <span className="d-block d-lg-none mb-3">{education.date}</span>
                {education.evaluation && (
                  <a
                    href={education.evaluation}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Evaluation of Credentials
                  </a>
                )}
              </div>
            </Col>
          </Row>
        ))}
      </Col>
    </Row>
  );
}
