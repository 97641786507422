import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import BasicLayout from "../layouts/BasicLayout";
import Perfil from "../images/perfil3.jpeg";
import Seo from "../components/seo";
import "./styles/index.scss";
import { Link } from "gatsby";

export default function Index() {
  return (
    <BasicLayout>
      <Seo title="Home" />
      <div className="body">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col xs={12} lg={6} className="col-image text-center">
              <div className="d-flex d-lg-block justify-content-center">
                <div className="avatar-container">
                  <Image className="avatar" src={Perfil} />
                </div>
              </div>
            </Col>
            <Col xs={12} lg={6} className="col-info text-align-center">
              <h1>Hello</h1>
              <h3>A Bit About Me</h3>
              <p>
                Software Engineer with 5 years of experience developing
                technological solutions for companies from different industries
                using multiple technologies like Node.js. I focus on developing
                high-quality, scalable, and maintainable software that meets
                customer requirements and provides an excellent user experience.
              </p>
              <p>
                I am passionate about technology and how it can transform our
                world. This is why I am always aware of the latest technological
                trends and always look for ways to apply them in my work.
              </p>
              <p>
                I have skills in problem-solving, task organization, and time
                management. I like working in a team and collaborating with
                other engineers and designers to create innovative and effective
                solutions. I am proud to say that I have worked on successful
                projects and helped companies achieve technological goals.
              </p>
              <p>
                I pledge to continue learning and improving my technical and
                professional skills. If you are looking for an experienced and
                committed Software Engineer, please do not hesitate to contact
                me.
              </p>
              <div className="buttons d-flex justify-content-center justify-content-md-start">
                <button>
                  <Link to="/resume/">Resume</Link>
                </button>
                <button>
                  <Link to="/projects/">Projects</Link>
                </button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </BasicLayout>
  );
}
